// Generated by Framer (926e2eb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["wvRuOD5Cl"];

const variantClassNames = {wvRuOD5Cl: "framer-v-1iw2k9h"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "wvRuOD5Cl", title: zs_2eIZIs = "Let's talk", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "wvRuOD5Cl", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-cmS4m", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-1iw2k9h", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"wvRuOD5Cl"} ref={ref} style={{...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNzAw", "--framer-font-size": "54px", "--framer-font-weight": "700", "--framer-letter-spacing": "-3px", "--framer-line-height": "1em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Let's talk</motion.p></React.Fragment>} className={"framer-ozz2im"} fonts={["GF;Inter-700"]} layoutDependency={layoutDependency} layoutId={"speu6tGeM"} style={{"--extracted-r6o4lv": "var(--token-fb63d21e-2930-4a00-8ef1-606d72b7b50f, rgb(51, 51, 51)) ", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={zs_2eIZIs} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-cmS4m [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cmS4m * { box-sizing: border-box; }", ".framer-cmS4m .framer-53zwhw { display: block; }", ".framer-cmS4m .framer-1iw2k9h { align-content: flex-end; align-items: flex-end; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-cmS4m .framer-ozz2im { flex: none; height: 48px; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-cmS4m .framer-1iw2k9h { gap: 0px; } .framer-cmS4m .framer-1iw2k9h > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-cmS4m .framer-1iw2k9h > :first-child { margin-left: 0px; } .framer-cmS4m .framer-1iw2k9h > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 206.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"zs_2eIZIs":"title"}
 */
const FramerQlrBQEtAw: React.ComponentType<Props> = withCSS(Component, css, "framer-cmS4m") as typeof Component;
export default FramerQlrBQEtAw;

FramerQlrBQEtAw.displayName = "let;s";

FramerQlrBQEtAw.defaultProps = {height: 48, width: 206.5};

addPropertyControls(FramerQlrBQEtAw, {zs_2eIZIs: {defaultValue: "Let's talk", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerQlrBQEtAw, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/QlrBQEtAw:default", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf", weight: "700"}])